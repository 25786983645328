/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import Place from "@mui/icons-material/Place";
import NewListing from "../../components/listings/NewListing";
export const Head = () => {
  const _components = Object.assign({
    title: "title"
  }, _provideComponents());
  return React.createElement(React.Fragment, null, React.createElement(_components.title, null, "FreshlyShopped - Add New Listing"));
};
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Add Listing"), "\n", React.createElement(NewListing));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
