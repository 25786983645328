import React, {useState, useEffect} from "react";
import { useForm } from "react-hook-form";
import {Box, Button, Field, Flex, Image, Input, Label, Text, Textarea} from "theme-ui";
import Add from "@mui/icons-material/Send";
import DownArrow from "@mui/icons-material/KeyboardArrowDown";
import UpArrow from "@mui/icons-material/KeyboardArrowUp";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Resizer from "react-image-file-resizer";
import ActionButton from "../ActionButton";
import useIsStillMounted from "../useIsStillMounted";
import {endpoint} from "../../lib/endpoint";
import {navigate} from "gatsby";

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      800,
      800,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

// from https://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata
function dataURItoBlob(dataURI: string) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString: string;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else
    byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], {type:mimeString});
}

type NearbyVenue = {
  id: string,
  name: string,
}

type NewListingFormProps = {
  nearbyVenues: NearbyVenue[],
  location: { lat: Number, lon: Number }
}

export default ({ nearbyVenues, location }: NewListingFormProps) => {

  const { register, handleSubmit, watch, formState: { errors, isSubmitting,
    dirtyFields: { image: isImageDirty } } } = useForm();
  const isMounted = useIsStillMounted();
  const [scaledImage, setScaledImage] = useState(undefined);
  const [showOptionalFields, setShowOptionalFields] = useState(false);

  const imageFormValue = watch("image");

  const image = imageFormValue && imageFormValue.length > 0 && imageFormValue[0];

  console.log("nearby venues", nearbyVenues);

  const onSubmit = async (data: any) => {

    console.log("onSubmit, data", data);

    const formData = new FormData(document.getElementById("add-listing-form") as HTMLFormElement);

    formData.delete("image");
    if (scaledImage) {
      formData.append("image", dataURItoBlob(scaledImage));
    }

    formData.delete("price");
    formData.append("price_cents", "" + data.price * 100);
    formData.append("price_currency", "ZAR");

    const existingVenue = nearbyVenues.find(v => v.name === data.venue);
    if (existingVenue) {
      formData.append("venue_id", existingVenue.id);
    } else {
      formData.append("venue_name", data.venue);
      formData.append("venue_lat", "" + location.lat);
      formData.append("venue_lon", "" + location.lon);
    }

    try {
      const result = await global.fetch(`${endpoint}/listings`, {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        body: formData
      });
      console.log("POST done", result);
      if (result.status < 202) {
        global.alert("Your listing has been added");
        navigate("/");
      } else {
        const body = JSON.parse(await result.text());
        global.alert(`Unable to create listing: ${body.message || result.statusText}`);
      }
    } catch (e) {
      global.alert(`Oh no, an error occurred: ${(e as Error).message || ''} - Please try again, or refresh.`);
    }
  };

  console.log("render, image (from dirtyFields)", isImageDirty, image);
  console.log("scaledImage", scaledImage);

  useEffect(() => {
    let mounted = true;
    if (isImageDirty && isMounted()) {
      if (image) {
        console.log("about to resize image", image);
        resizeFile(image).then((resized) => {
          if (mounted) { setScaledImage(resized as any) }
        });
      }
    }
    return () => { mounted = false; }
  }, [isImageDirty, image, isMounted]);
   
  return <>
    <form id="add-listing-form" onSubmit={handleSubmit(onSubmit)}>

      { scaledImage &&
          <Label mt={3} htmlFor="image">
            <Flex sx={{ alignItems: 'center', flexDirection: 'column', width: '100%' }}>
              <Image width={300} height={300} src={scaledImage} alt="freshly snapped photo" />
              <Button as={Box} sx={{ p: 2, m: 2, borderRadius: 999, transition: '0.3s all' }}>
                <Flex sx={{ flexDirection: "row", alignItems: "center" }}>
                  <PhotoCamera />
                  <Box ml={2}>Take another Photo</Box>
                </Flex>
              </Button>
            </Flex>
          </Label>
      }
      { !scaledImage && 
        <Label mt={3} htmlFor="image">
          <Flex sx={{ alignItems: 'center', flexDirection: 'column', width: '100%' }}>
          <Button as={Box} sx={{ p: 4, m: 3, borderRadius: 999, transition: '0.3s all' }}>
            <Flex sx={{ flexDirection: "row", alignItems: "center" }}>
              <PhotoCamera fontSize="large" />
              <Box ml={2}>Snap a Photo</Box>
            </Flex>
          </Button>
          { errors.image && <Text sx={{ mb: 3 }} variant="error">You need to take a photo for the listing</Text> }
          </Flex>
        </Label>
      }
      <Input
        style={{ display: 'none' }}
        id="image"
        type="file"
        capture="environment"
        {...register("image", { required: true })}
        accept="image/png, image/jpeg"
        variant={errors.image ? "error" : undefined}
      />

      <Field label="Title" {...register("title", { required: true, })}
        placeholder="What is the offer?" mb={3}
        aria-invalid={errors.title ? "true" : "false"}
        variant={errors.title ? "error" : "input"}
      />
      <Field mb={3} label="Price (in ZAR)" type="number" step="any" {...register("price", { required: true, })}
        variant={errors.price ? "error" : "input"}
      />
      <Label htmlFor="venue">Venue</Label>
      <Field mb={3} {...register("venue", { required: true })} type="text" list="venues"
        aria-invalid={errors.venue ? "true" : "false"}
        variant={errors.venue ? "error" : "input"}
      />
      <datalist id="venues">
        { nearbyVenues.map(v => <option key={v.id}>{v.name}</option> ) }
      </datalist>

      <Button as={Box} variant="formToggle" sx={{ px: 0 }} onClick={() => {
        setShowOptionalFields(value => !value);
      }}>
        <Flex sx={{ flexDirection: "row", alignItems: "center" }}>
        { showOptionalFields ? <UpArrow /> : <DownArrow /> }
          <Text>{ showOptionalFields ? 'Hide' : 'Show' } optional fields</Text>
        </Flex>
      </Button>

      <Box sx={{ mb: showOptionalFields ? 4 : 0, transition: '0.3s all', maxHeight: showOptionalFields ? '500px' : '0px', overflow: 'hidden' }}>
        <Label htmlFor="description">Description (optional)</Label>
        <Textarea mb={3} rows={4} {...register("description")} placeholder="Describe the product you found, add details that may help other buyers" />
        <Label htmlFor="ends_at">When does this offer end? (optional)</Label>
        <Field {...register("ends_at", {
          validate: value => {
            console.log("end_time validate, value", value);
            const now = new Date();
            return !value || new Date(value) > now;
          }
        })} type="date" />
        { errors.ends_at && <Text sx={{ mb: 3 }} variant="error">The end date must be tomorrow or later.</Text> }
      </Box>

      <Box sx={{ mt: 3, mb: 4 }}>
        <ActionButton text={isSubmitting ? "Submitting..." : "Add my listing"} rightIcon={<Add />} disabled={isSubmitting} />
      </Box>

    </form>
  </>;
}

