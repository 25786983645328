import React, {useState} from "react";
import {Box, Flex, Text} from "theme-ui";
import LoginIcon from "@mui/icons-material/Login";
import {endpoint} from "../../lib/endpoint";
import useAuthenticatedFetch from "../../useAuthenticatedFetch";
import ActionButton from "../ActionButton";
import AuthenticatedDataView from "../AuthenticatedDataView";
import LoadingIndicator from "../LoadingIndicator";
import NewListingForm from "./NewListingForm";
import LocationPicker from "../LocationPicker";
import {navigate} from "gatsby";

const NewListingWithLocationAndLoggedIn = ({ profile, location }) => {

  console.log("profile, location", profile, location);
  return <AuthenticatedDataView
    fetchUrl={`${endpoint}/venues/nearby?lat=${location.coords.latitude}&lon=${location.coords.longitude}`}
    render={data => {
      return <NewListingForm nearbyVenues={data.venues} location={{
        lat: location.coords.latitude,
        lon: location.coords.longitude
      }} />
    }}
  />
}

const NewListingWhenLoggedIn = ({ profile }) => {
  const [location, setLocation] = useState<GeolocationPosition | undefined>(undefined);
  return <>
    { location && <NewListingWithLocationAndLoggedIn location={location} profile={profile} /> }
    { !location &&
      <LocationPicker onCancel={() => navigate("/")} onLocationPicked={location => {
        setLocation(location);
      }} />
    }
  </>;
}

const ForcingLogin = () => {
  const [fetchAuthenticated, loggingIn] = useAuthenticatedFetch(`${endpoint}/my_profile`);

  if (loggingIn) {
    return <LoadingIndicator />
  } else {
    return <Box>
      <Box sx={{ py: 3 }} >
        <Text>In order to add a listing, you need to be logged in.</Text>
      </Box>
      <ActionButton icon={<LoginIcon />} text="Login" onClick={() => {
        fetchAuthenticated();
      }} />
    </Box>;
  }
}

const NewListingPossiblyLoggedIn = ({ profile }) => {
  if (profile?.loggedIn) {
    return <NewListingWhenLoggedIn profile={profile} />
  } else {
    return <ForcingLogin />;
  }
}

export default () => {

  return <AuthenticatedDataView
    fetchUrl={`${endpoint}/my_profile?forceLogin=false`}
    render={data => <NewListingPossiblyLoggedIn profile={data} />}
  />;
}
