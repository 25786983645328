import React, {useEffect, useState} from "react";
import {Box, Text} from "theme-ui";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvent,
  // useMap,
} from "react-leaflet";
import * as L from "leaflet";
import useHasMounted from "../lib/useHasMounted";
import ActionButton from "./ActionButton";
import LoadingIndicator from "./LoadingIndicator";

// this works, despite the ts error
import * as styles from "./LocationRefinementView.module.css";

type LocationRefinementViewProps = {
  onLocationPicked: (location: GeolocationPosition) => void,
  initialLocation: GeolocationPosition
}

const EventsComponent = ({ onNewLocationPicked }) => {
  const map = useMapEvent('click', e => {
    console.log("click event", e);
    console.log("click event, map", map);
    onNewLocationPicked(e.latlng.lat, e.latlng.lng);
  });
  const map2 = useMapEvent('move', e => {
    console.log("move event", e);
    const center = map2.getCenter();
    onNewLocationPicked(center.lat, center.lng);
  });
  return null;
}

type SimplifiedLocation = {
  coords: {
    latitude: number,
    longitude: number
  }
};

const LocationRefinementView = ({ onLocationPicked, initialLocation }: LocationRefinementViewProps) => {
  const hasMounted = useHasMounted();
  const [location, setLocation] = useState<SimplifiedLocation>(initialLocation);
  const { latitude, longitude } = location?.coords;

  const isBrowser = typeof window !== "undefined"

  // with this effect, we don't display the map to confirm location, we rather directly
  // choose the location we've received
  useEffect(() => {
    if (hasMounted && isBrowser && location) {
      onLocationPicked(location as GeolocationPosition);
    }
  }, [hasMounted, isBrowser, location, onLocationPicked]);

  if (hasMounted && isBrowser) {

    const MyLocationIcon = L.divIcon({
      className: styles.myLocationIcon
    });

    return (
      <>
        <MapContainer style={{ height: 300 }} center={[latitude, longitude]} zoom={13} scrollWheelZoom={true}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <EventsComponent onNewLocationPicked={(lat, lng) => {
            setLocation({ coords: { latitude: lat, longitude: lng }});
          }}/>
          <Marker icon={MyLocationIcon} position={[latitude, longitude]}>
            <Popup>
              Pick your location, then push "Confirm" below.
              Move the map to find listings at locations elsewhere.
            </Popup>
          </Marker>
        </MapContainer>
        <Box sx={{ pt: 3 }}>
          <ActionButton text="Confirm" onClick={() => onLocationPicked(location as GeolocationPosition)} />
          <Box sx={{ pt: 3 }}>
            <Text>Click on the map to choose another location.</Text>
          </Box>
        </Box>
      </>
    );
  } else {
    return <LoadingIndicator />
  }
}

export default LocationRefinementView;
