import React, {useEffect, useState} from "react";
import useIsStillMounted from "./useIsStillMounted";
import LoadingIndicator from "./LoadingIndicator";
import LocationRefinementView from "./LocationRefinementView";

type LocationPickerProps = {
  onCancel: () => void,
  onLocationPicked: (position: GeolocationPosition) => void
}

export default ({ onCancel, onLocationPicked }: LocationPickerProps) => {
  const isMounted = useIsStillMounted();
  const [position, setPosition] = useState<GeolocationPosition>();
  const [error, setError] = useState<GeolocationPositionError>();

  useEffect(() => {
    const success: PositionCallback = (position: GeolocationPosition) => {
      console.log("position", position);
      if (isMounted()) {
        setPosition(position);
      }
    };
    const error: PositionErrorCallback = (err: GeolocationPositionError) => {
      if (isMounted()) {
        setError(err);
      }
    };
    navigator.geolocation.getCurrentPosition(success, error);
  }, [isMounted, setPosition, setError]);

  if (error) {
    return <div>
      <p>Unable to get your location, check ... to get help on how to retry this.</p>
      <p>Error: {error.message}</p>
      <button onClick={() => onCancel()}>Ok</button>
    </div>;
  }
  if (position?.coords) {
    const { latitude, longitude, accuracy } = position.coords;
    console.log("lat, lon, accuracy", latitude, longitude, accuracy);
    return <LocationRefinementView initialLocation={position} onLocationPicked={onLocationPicked} />
  }
  return <div>
    <div>Fetching location...</div>
    <LoadingIndicator />
  </div>;
};
